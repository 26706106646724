<template>
  <question-container
    :question="game.questionTwo"
    :answer="currentPlayer.answerTwo"
    :answer-list="categoryList"
    :action-on-submit="actionOnSubmit"
    :waiting-message="$t('ontime.gameFinishedMessage')"
    waiting-color="success"
    picture="question-two.jpg"
  />
</template>

<script>
import Helpers from 'src/utils/helpers'
import QuestionContainer from './QuestionContainer'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'QuestionTwoContainer',
  components: {
    QuestionContainer,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    actionOnSubmit () {
      return AVAILABLE_ACTIONS.answerQuestionTwo
    },
    categoryList () {
      const messages = this.$i18n.messages[this.$i18n.locale]
      return [
        { key: 'day-before', value: this.$t('ontime.dayBefore') },
        ...Helpers.getKeyValueList(messages.ontime.choiceList),
      ]
    },
  },
}
</script>
